<template>
    <div class="index">

        <div class="banner">
            <img :src="$store.state.bannerUrl + $store.state.bannerImgs.xlkp" alt="">
        </div>
        <!-- 超链接详情页 -->
        <div :class="isPc === 'true' ? 'main' : 'phoneStyle main'">
            <div class="content">

                <p class="title p2">{{ articleInfo.title }}</p>
                <p class="p1" v-if="message" style="text-align: center;">{{ message }}</p>
                <p class="time p3">{{ articleInfo.created }}</p>
                <p v-html="articleInfo.content"></p>


            </div>

        </div>
        <div class="backBtn">
            <span class="span1" @click="back">返回</span>
        </div>
    </div>
</template>

<script>
import { newsDetail } from '@/api/news'
export default {
    data() {
        return {
            isPc: true,
            articleInfo: {},
            article: {},
            message: null,
            articleId: null,
        }
    },
    methods: {
        // 获取文章详情
        async articleDetail() {
            const res = await newsDetail({ id: Number(this.articleId) });
            console.log(res);
            if (res.state === 'ok' && res.detail) {
                this.articleInfo = res.detail;
            } else {
                this.message = res.message
            }
        },
        back() {
            this.$router.push({
                path: '/universal/detail',
                query: {
                    ...this.article
                }
            })
        }
    },
    created() {
        this.isPc = sessionStorage.getItem('isPc')
        let path = (this.$route.path).split('/');
        this.articleId = path[2];
        // console.log()
        this.article = JSON.parse(sessionStorage.getItem('article'))
        this.articleDetail();

    }
}
</script>

<style lang="scss" scoped>
.index {
    ::v-deep .image.image_resized.image-style-block-align-left {
        width: 90% !important;

        img {
            width: 100%;
        }
    }

    .banner {
        img {
            width: 100%;
        }
    }

    // .phoneStyle.main {
    //     width: 90%;
    // }
    // .main{
    //     width: ;
    // }

    .content {
        margin: auto;
        width: 80%;
        margin-top: 40px;
        border: 1px solid #DDDDDD;
        padding-bottom: 39px;
        min-height: 500px;

        .title {
            background: #EEEEEE;
            padding: 14px 0px;
            text-align: center;
            font-weight: 600;
            color: var(--titleFont-color);
            line-height: 21px;

        }

        .time {
            color: var(--titleFont-color);
            text-align: right;
            margin: 20px 0px;
            // margin-bottom: 10px;
            // padding: 0  30px;
        }

        p {
            padding: 0 30px;


            ::v-deep img {
                width: 100% !important;
            }

            ::v-deep span {
                img {
                    width: 100% !important;
                }
            }
        }

        img {
            width: 100%;
        }

        // .text{

        // }
    }

    .backBtn {
        text-align: center;
        margin-top: 50px;

        span {
            cursor: pointer;
            display: inline-block;
            background: #FFFFFF;
            border: 1px solid var(--custom-color);
            padding: 10px 32px;
            font-weight: 600;
            color: var(--headFont-color);
        }

    }

}
</style>